import { default as indext7dfob6oupMeta } from "/vercel/path0/pages/download/index.vue?macro=true";
import { default as index1d2oHAK1NQMeta } from "/vercel/path0/pages/home/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as indexr7D9XoGpe3Meta } from "/vercel/path0/pages/tools/audio/index.vue?macro=true";
import { default as playerqbdq0D5KR4Meta } from "/vercel/path0/pages/tools/audio/vocal-remover/components/player.vue?macro=true";
import { default as indexoJ15jjdPZXMeta } from "/vercel/path0/pages/tools/audio/vocal-remover/index.vue?macro=true";
import { default as transcript2HLzhDzU8hMeta } from "/vercel/path0/pages/tools/audio/whisper/components/transcript.vue?macro=true";
import { default as use_45transcriber9cxEfbPgDQMeta } from "/vercel/path0/pages/tools/audio/whisper/hooks/use-transcriber.ts?macro=true";
import { default as use_45workerC5zcBLFRTSMeta } from "/vercel/path0/pages/tools/audio/whisper/hooks/use-worker.ts?macro=true";
import { default as indexXbq1rCM9OzMeta } from "/vercel/path0/pages/tools/audio/whisper/index.vue?macro=true";
import { default as AudioUtilsY1Yg30WeWbMeta } from "/vercel/path0/pages/tools/audio/whisper/utils/AudioUtils.ts?macro=true";
import { default as BlobFixCpl9T40ZyCMeta } from "/vercel/path0/pages/tools/audio/whisper/utils/BlobFix.ts?macro=true";
import { default as ConstantsUPLE6KRyyKMeta } from "/vercel/path0/pages/tools/audio/whisper/utils/Constants.ts?macro=true";
import { default as workerdhGk2hf4xsMeta } from "/vercel/path0/pages/tools/audio/whisper/utils/worker.ts?macro=true";
import { default as indexRoO8rSCq49Meta } from "/vercel/path0/pages/tools/image/bg-remover/index.vue?macro=true";
import { default as clientlMNld0xvq1Meta } from "/vercel/path0/pages/tools/image/compressor/client.vue?macro=true";
import { default as indexTTH8sCVoZYMeta } from "/vercel/path0/pages/tools/image/compressor/index.vue?macro=true";
import { default as indexZzn3eXcd91Meta } from "/vercel/path0/pages/tools/image/converter/index.vue?macro=true";
import { default as PhotoEditorLPKWvf8lmVMeta } from "/vercel/path0/pages/tools/image/editor/components/PhotoEditor.vue?macro=true";
import { default as i18nVrJky2ump2Meta } from "/vercel/path0/pages/tools/image/editor/i18n.ts?macro=true";
import { default as indexeeUGvsH4hFMeta } from "/vercel/path0/pages/tools/image/editor/index.vue?macro=true";
import { default as indexiksuZSMeWhMeta } from "/vercel/path0/pages/tools/image/index.vue?macro=true";
import { default as index4j2rQXmZFiMeta } from "/vercel/path0/pages/tools/index.vue?macro=true";
import { default as indexQS1djB19qXMeta } from "/vercel/path0/pages/tools/pdf/compressor/index.vue?macro=true";
import { default as indexSbWfISJktlMeta } from "/vercel/path0/pages/tools/pdf/convert-from/index.vue?macro=true";
import { default as indexdaAF0k4lLRMeta } from "/vercel/path0/pages/tools/pdf/convert-to/index.vue?macro=true";
import { default as indexMrbNWOI3RnMeta } from "/vercel/path0/pages/tools/pdf/index.vue?macro=true";
import { default as copybookPt0zg6flaSMeta } from "/vercel/path0/pages/tools/text/copybook/components/copybook.vue?macro=true";
import { default as indexZ8QcfIxTaFMeta } from "/vercel/path0/pages/tools/text/copybook/index.vue?macro=true";
import { default as indexwYOlvYxJpVMeta } from "/vercel/path0/pages/tools/text/index.vue?macro=true";
import { default as mind_45mappingTA7QRE3LVyMeta } from "/vercel/path0/pages/tools/text/mind-mapping/components/mind-mapping.vue?macro=true";
import { default as index8KNg171joSMeta } from "/vercel/path0/pages/tools/text/mind-mapping/index.vue?macro=true";
import { default as indexwXUy89TVOPMeta } from "/vercel/path0/pages/tools/video/compressor/index.vue?macro=true";
import { default as indexdUeRCs9PGfMeta } from "/vercel/path0/pages/tools/video/converter/index.vue?macro=true";
import { default as indexNPCNssXCobMeta } from "/vercel/path0/pages/tools/video/index.vue?macro=true";
import { default as countdown_45maskYwQRT91UgmMeta } from "/vercel/path0/pages/tools/video/recorder/components/countdown-mask.vue?macro=true";
import { default as electron_45media_45select_45dialogVABN9uJYT8Meta } from "/vercel/path0/pages/tools/video/recorder/components/electron-media-select-dialog.vue?macro=true";
import { default as previewZQtTDpb5Z2Meta } from "/vercel/path0/pages/tools/video/recorder/components/preview.vue?macro=true";
import { default as recording_45inputs3HU2v6gADZMeta } from "/vercel/path0/pages/tools/video/recorder/components/recording-inputs.vue?macro=true";
import { default as recording_45resultAEoYp2PSobMeta } from "/vercel/path0/pages/tools/video/recorder/components/recording-result.vue?macro=true";
import { default as recording_45typesul8aXs84XMMeta } from "/vercel/path0/pages/tools/video/recorder/components/recording-types.vue?macro=true";
import { default as recordingep9AJAbBtdMeta } from "/vercel/path0/pages/tools/video/recorder/components/recording.vue?macro=true";
import { default as index0K3MxXlvarMeta } from "/vercel/path0/pages/tools/video/recorder/index.vue?macro=true";
import { default as utilsRHGSJYhk6uMeta } from "/vercel/path0/pages/tools/video/recorder/utils.ts?macro=true";
export default [
  {
    name: "download",
    path: "/download",
    meta: indext7dfob6oupMeta || {},
    component: () => import("/vercel/path0/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/vercel/path0/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "音频工具",
    path: "/tools/audio",
    component: () => import("/vercel/path0/pages/tools/audio/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-audio-vocal-remover-components-player",
    path: "/tools/audio/vocal-remover/components/player",
    component: () => import("/vercel/path0/pages/tools/audio/vocal-remover/components/player.vue").then(m => m.default || m)
  },
  {
    name: "人声分离",
    path: "/tools/audio/vocal-remover",
    meta: indexoJ15jjdPZXMeta || {},
    component: () => import("/vercel/path0/pages/tools/audio/vocal-remover/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-audio-whisper-components-transcript",
    path: "/tools/audio/whisper/components/transcript",
    component: () => import("/vercel/path0/pages/tools/audio/whisper/components/transcript.vue").then(m => m.default || m)
  },
  {
    name: "tools-audio-whisper-hooks-use-transcriber",
    path: "/tools/audio/whisper/hooks/use-transcriber",
    component: () => import("/vercel/path0/pages/tools/audio/whisper/hooks/use-transcriber.ts").then(m => m.default || m)
  },
  {
    name: "tools-audio-whisper-hooks-use-worker",
    path: "/tools/audio/whisper/hooks/use-worker",
    component: () => import("/vercel/path0/pages/tools/audio/whisper/hooks/use-worker.ts").then(m => m.default || m)
  },
  {
    name: "音视频转文字",
    path: "/tools/audio/whisper",
    component: () => import("/vercel/path0/pages/tools/audio/whisper/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-audio-whisper-utils-AudioUtils",
    path: "/tools/audio/whisper/utils/AudioUtils",
    component: () => import("/vercel/path0/pages/tools/audio/whisper/utils/AudioUtils.ts").then(m => m.default || m)
  },
  {
    name: "tools-audio-whisper-utils-BlobFix",
    path: "/tools/audio/whisper/utils/BlobFix",
    component: () => import("/vercel/path0/pages/tools/audio/whisper/utils/BlobFix.ts").then(m => m.default || m)
  },
  {
    name: "tools-audio-whisper-utils-Constants",
    path: "/tools/audio/whisper/utils/Constants",
    component: () => import("/vercel/path0/pages/tools/audio/whisper/utils/Constants.ts").then(m => m.default || m)
  },
  {
    name: "tools-audio-whisper-utils-worker",
    path: "/tools/audio/whisper/utils/worker",
    component: () => import("/vercel/path0/pages/tools/audio/whisper/utils/worker.ts").then(m => m.default || m)
  },
  {
    name: "在线抠图",
    path: "/tools/image/bg-remover",
    meta: indexRoO8rSCq49Meta || {},
    component: () => import("/vercel/path0/pages/tools/image/bg-remover/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-image-compressor-client",
    path: "/tools/image/compressor/client",
    component: () => import("/vercel/path0/pages/tools/image/compressor/client.vue").then(m => m.default || m)
  },
  {
    name: "图片压缩",
    path: "/tools/image/compressor",
    component: () => import("/vercel/path0/pages/tools/image/compressor/index.vue").then(m => m.default || m)
  },
  {
    name: "图片格式转换",
    path: "/tools/image/converter",
    meta: indexZzn3eXcd91Meta || {},
    component: () => import("/vercel/path0/pages/tools/image/converter/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-image-editor-components-PhotoEditor",
    path: "/tools/image/editor/components/PhotoEditor",
    component: () => import("/vercel/path0/pages/tools/image/editor/components/PhotoEditor.vue").then(m => m.default || m)
  },
  {
    name: "tools-image-editor-i18n",
    path: "/tools/image/editor/i18n",
    component: () => import("/vercel/path0/pages/tools/image/editor/i18n.ts").then(m => m.default || m)
  },
  {
    name: "图片编辑器",
    path: "/tools/image/editor",
    component: () => import("/vercel/path0/pages/tools/image/editor/index.vue").then(m => m.default || m)
  },
  {
    name: "图片工具",
    path: "/tools/image",
    component: () => import("/vercel/path0/pages/tools/image/index.vue").then(m => m.default || m)
  },
  {
    name: "工具列表",
    path: "/tools",
    component: () => import("/vercel/path0/pages/tools/index.vue").then(m => m.default || m)
  },
  {
    name: "PDF压缩",
    path: "/tools/pdf/compressor",
    component: () => import("/vercel/path0/pages/tools/pdf/compressor/index.vue").then(m => m.default || m)
  },
  {
    name: "从PDF转换",
    path: "/tools/pdf/convert-from",
    component: () => import("/vercel/path0/pages/tools/pdf/convert-from/index.vue").then(m => m.default || m)
  },
  {
    name: "转为PDF",
    path: "/tools/pdf/convert-to",
    component: () => import("/vercel/path0/pages/tools/pdf/convert-to/index.vue").then(m => m.default || m)
  },
  {
    name: "PDF工具",
    path: "/tools/pdf",
    component: () => import("/vercel/path0/pages/tools/pdf/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-text-copybook-components-copybook",
    path: "/tools/text/copybook/components/copybook",
    component: () => import("/vercel/path0/pages/tools/text/copybook/components/copybook.vue").then(m => m.default || m)
  },
  {
    name: "字帖生成",
    path: "/tools/text/copybook",
    component: () => import("/vercel/path0/pages/tools/text/copybook/index.vue").then(m => m.default || m)
  },
  {
    name: "文本工具",
    path: "/tools/text",
    component: () => import("/vercel/path0/pages/tools/text/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-text-mind-mapping-components-mind-mapping",
    path: "/tools/text/mind-mapping/components/mind-mapping",
    component: () => import("/vercel/path0/pages/tools/text/mind-mapping/components/mind-mapping.vue").then(m => m.default || m)
  },
  {
    name: "思维导图",
    path: "/tools/text/mind-mapping",
    component: () => import("/vercel/path0/pages/tools/text/mind-mapping/index.vue").then(m => m.default || m)
  },
  {
    name: "视频压缩",
    path: "/tools/video/compressor",
    meta: indexwXUy89TVOPMeta || {},
    component: () => import("/vercel/path0/pages/tools/video/compressor/index.vue").then(m => m.default || m)
  },
  {
    name: "视频格式转换",
    path: "/tools/video/converter",
    meta: indexdUeRCs9PGfMeta || {},
    component: () => import("/vercel/path0/pages/tools/video/converter/index.vue").then(m => m.default || m)
  },
  {
    name: "视频工具",
    path: "/tools/video",
    component: () => import("/vercel/path0/pages/tools/video/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-video-recorder-components-countdown-mask",
    path: "/tools/video/recorder/components/countdown-mask",
    component: () => import("/vercel/path0/pages/tools/video/recorder/components/countdown-mask.vue").then(m => m.default || m)
  },
  {
    name: "tools-video-recorder-components-electron-media-select-dialog",
    path: "/tools/video/recorder/components/electron-media-select-dialog",
    component: () => import("/vercel/path0/pages/tools/video/recorder/components/electron-media-select-dialog.vue").then(m => m.default || m)
  },
  {
    name: "tools-video-recorder-components-preview",
    path: "/tools/video/recorder/components/preview",
    component: () => import("/vercel/path0/pages/tools/video/recorder/components/preview.vue").then(m => m.default || m)
  },
  {
    name: "tools-video-recorder-components-recording-inputs",
    path: "/tools/video/recorder/components/recording-inputs",
    component: () => import("/vercel/path0/pages/tools/video/recorder/components/recording-inputs.vue").then(m => m.default || m)
  },
  {
    name: "tools-video-recorder-components-recording-result",
    path: "/tools/video/recorder/components/recording-result",
    component: () => import("/vercel/path0/pages/tools/video/recorder/components/recording-result.vue").then(m => m.default || m)
  },
  {
    name: "tools-video-recorder-components-recording-types",
    path: "/tools/video/recorder/components/recording-types",
    component: () => import("/vercel/path0/pages/tools/video/recorder/components/recording-types.vue").then(m => m.default || m)
  },
  {
    name: "tools-video-recorder-components-recording",
    path: "/tools/video/recorder/components/recording",
    component: () => import("/vercel/path0/pages/tools/video/recorder/components/recording.vue").then(m => m.default || m)
  },
  {
    name: "录屏工具",
    path: "/tools/video/recorder",
    component: () => import("/vercel/path0/pages/tools/video/recorder/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-video-recorder-utils",
    path: "/tools/video/recorder/utils",
    component: () => import("/vercel/path0/pages/tools/video/recorder/utils.ts").then(m => m.default || m)
  }
]